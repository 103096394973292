import { Link } from "@remix-run/react";

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  variant?: "primary" | "secondary" | "outlined";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  children: React.ReactNode;
  loading?: boolean;
  to?: string;
  target?: string;
}

export const Button = ({
  onClick,
  children,
  variant,
  size = "md",
  loading,
  className,
  disabled,
  to,
  ...props
}: ButtonProps) => {
  let sizeClass = "px-3.5 py-2.5 text-sm";
  switch (size) {
    case "xs":
      sizeClass = "py-1 px-2 text-xs";
      break;
    case "sm":
      sizeClass = "py-1 px-2 text-sm";
      break;
    case "md":
      sizeClass = "py-1.5 px-2.5 text-md";
      break;
    case "lg":
      sizeClass = "py-2 px-3 text-md";
      break;
    case "xl":
      sizeClass = "py-2.5 px-3.5 text-lg";
      break;
  }

  let variantClass = "text-gray-900 bg-lime-500 hover:bg-lime-400";
  if (variant === "secondary") {
    variantClass = "bg-gray-800/50 hover:bg-gray-800/60";
  }

  if (variant === "outlined") {
    variantClass =
      "bg-gray-800 border-2 border-lime-500 hover:bg-lime-400 text-gray-50 hover:text-gray-900";
  }

  const combinedClassName = `${variantClass} ${
    disabled ? "cursor-not-allowed opacity-30" : "cursor-pointer"
  } ${
    className || ""
  } rounded-lg text-center font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 ${sizeClass}`;

  if (to) {
    return (
      <Link to={to} className={combinedClassName} {...props}>
        {loading ? "Submitting..." : children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      disabled={disabled || loading}
      className={combinedClassName}
      onClick={onClick}
      {...props}
    >
      {loading ? "Submitting..." : children}
    </button>
  );
};
